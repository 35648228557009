$red: blue;

$mobile: 'only screen and (max-width: 768px)';
$desktop: 'only screen and (min-width: 769px)';
$action-color: #fd6568;
$bp-collapse-menu: 1040px;
$text: #333333;
$primary: #0082f1; // #0073d5; //#092176;

body {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
/** {
  overflow-x: hidden;
}*/

.app-content {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    /*flex-wrap: wrap;*/
    height: 100%;
    // div {
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: space-between;
    // }
    .nav-bar {
        width: 100%;
        position: fixed;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        bottom: 0px;
        border: 1px solid red;
        box-sizing: border-box;
    }
}