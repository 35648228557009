$red: blue;

$mobile: 'only screen and (max-width: 768px)';
$desktop: 'only screen and (min-width: 769px)';
$action-color: #fd6568;
$bp-collapse-menu: 1040px;
$text: #333333;
$primary: #0082f1; // #0073d5; //#092176;

body {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
/** {
  overflow-x: hidden;
}*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #333333;
  font-family: lato, sans-serif;
  font-size: 30px;
  line-height: 1.5;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1040px) {
  .menu-bar img {
    width: auto;
    height: 24px;
  }
}

//min-width 769
@media #{$desktop} {
  .mobile-only {
    display: none !important;
  }
  input[type='submit'],
  .btn {
    line-height: 1.75em;
    text-align: center;
    border-radius: 1000px;
    padding: 8px 30px;
    cursor: pointer;
  }

  input[type='submit'],
  .btn-primary {
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    background-color: $action-color;
    border: 0;
    box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.3);
    margin-bottom: 5px;
    line-height: 3;
  }
}

//max-width 768
@media #{$mobile} {
  .desktop-only {
    display: none !important;
  }
  div.mobile-only {
    background: $primary;
    width: 100%;
    color: #fff;
    .nav {
      font-size: 30px;
      padding: 0 10px;
      height: 100%;
    }
  }
  .pagewrap {
    padding: 0 20px;
  }
  .padded-container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .flex-row {
    flex-direction: column;
  }

  input[type='submit'],
  .btn {
    line-height: 3.5em;
    text-align: center;
    border-radius: 1000px;
    padding: 16px 60px;
    cursor: pointer;
  }

  input[type='submit'],
  .btn-primary {
    color: white;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
    background-color: $action-color;
    border: 0;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.3);
    margin-bottom: 10px;
  }
}
.text-justify p,
p.text-justify {
  text-align: justify;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.ant-affix {
  position: absolute !important;
}

a {
  text-decoration: none;
  color: $action-color;
}
p.extra-top-margin {
  margin-top: 1.5em;
}

img:not(.news-box img) {
  width: 100%;
  display: block;
}


@media #{$desktop} {
  body {
    font-size: 16px !important;
    line-height: 1.5;
    font-weight: 400;
  }

  .pagewrap {
    padding: 0 40px 40px 40px;
  }
}

.flex-top {
  align-items: baseline !important;
}
.flex-row {
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;

  @media #{$mobile} {
    flex-direction: column;

    > * {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    .column-narrow {
      h2 {
        text-align: center;
      }
    }
  }

  @media #{$desktop} {
    flex-direction: row;
    align-items: center;

    .column-wide {
      flex-grow: 6;
    }

    .column-narrow {
      flex-grow: 4;
    }

    .column-70 {
      flex-grow: 7;
    }

    .column-30 {
      flex-grow: 3;
    }

    > * {
      margin: 20px 40px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &.narrow-margin > * {
      margin-left: 10px;
      margin-right: 10px;
    }

    &.align-top {
      align-items: flex-start;

      p:first-child {
        margin-top: 0;
      }
    }

    > * {
      flex: 1 0 0;
    }
  }
}

ul li {
  margin-left: 16px;
}

.pagewrap .text-block {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  @media #{$desktop} {
    max-width: 960px;
  }
}

.ant-btn-primary {
  background: $action-color !important;
  border-color: $action-color !important;
  border-radius: 8px !important;
}
.ant-btn-link {
  color: $action-color !important;
}

h5.ant-typography,
div.ant-typography-h5,
div.ant-typography-h5 > textarea,
.ant-typography h5 {
  color: $action-color !important;
  font-weight: 500 !important;
}
a.ant-typography,
.ant-typography a {
  color: $action-color !important;
}
h1.ant-typography,
div.ant-typography-h1,
div.ant-typography-h1 > textarea,
.ant-typography h1 {
  line-height: 1.5 !important;
}

.formbox {
  @media #{$desktop} {
    width: 60%;
  }
  @media #{$mobile} {
    width: 90%;
  }

  margin: 30px auto;
}

.textbox {
  @media #{$desktop} {
    width: 60%;
  }
  @media #{$mobile} {
    width: 100%;
  }
  margin: 0 auto;
}
.padding40 {
  @media #{$desktop} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.ant-anchor-ink {
  display: none !important;
}

.adm-button {
  --border-style: none !important;
}

h2,
h3,
h4,
strong {
  color: $primary;
}

.text-serial {
  color: white;
  background-color: #092176;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
  border-radius: 1000px;
  margin-right: 0.5em;
  text-align: center;
}

.change-button {
  border: 1px solid !important;
  border-radius: 12px !important;
}
.ant-form-item-explain-error {
  text-align: left;
}
// .adm-swiper-horizontal .adm-swiper-track-inner {
//   align-items: center;
// }
.message .adm-swiper-horizontal .adm-swiper-track-inner {
  align-items: flex-start;
}
.adm-space-vertical > .adm-space-item:last-child {
  text-align: center;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  padding: 0;
}
.ant-menu-horizontal {
  background: transparent;
  color: #fff;
  //font-weight: 600;
  font-size: 14px;
  border-bottom: none;
  margin-right: 32px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: lato, sans-serif;
  font-weight: 700;
  margin-top: 0.5em;
  //line-height: 1.5;
}
.text-center-mobile {
  text-align: center !important;
}
.menu-bar .special {
  margin-left: 16px;
}
.anticon-left-circle,
.anticon-right-circle {
  font-size: 24px;
  @media #{$mobile} {
    font-size: 28px;
  }
}
/*#section2 {
  padding-top: 0;
}*/
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.position-middle {
  z-index: 50;
  top: 50%;
  left: 0;
  right: 0;
}

@media #{$mobile} {
  .position-middle {
/*    left: 22vw;
    right: auto;*/
  }
/*  .logo-and-toggle-nav {
    display: none !important;
  }*/
  
  .adm-swiper-horizontal .adm-swiper-track-inner{
    height: 100vh;
  }
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  font-weight: 500;
}

.pagewrap {
  @media #{$mobile} {
    .adm-swiper-horizontal .adm-swiper-track-inner{
      height: 100%;
    }
  }
}
video{
  width:100%;
}